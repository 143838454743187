import { Bounce, ToastOptions, toast, Id } from "react-toastify";

export const DEFAULT_TOAST: ToastOptions = {
  autoClose: 2500,
  hideProgressBar: true,
  position: "top-center",
  theme: "colored",
  transition: Bounce,
  type: "info",
};

export const toastSuccess = (message: string): Id =>
  toast(message, {
    ...DEFAULT_TOAST,
    type: "success",
  });

export const toastWarning = (message: string): Id =>
  toast(message, {
    ...DEFAULT_TOAST,
    type: "warning",
  });

export const toastError = (message: string): Id =>
  toast(message, {
    ...DEFAULT_TOAST,
    type: "error",
  });
