import React, { useState } from "react";
import { Column, Table } from "@120wateraudit/waterworks";
import {
  useGenerateCSVExportMutation,
  useGetSIDWISExportsQuery,
} from "src/services";
import Export from "src/types/Export";
import { DownloadIcon } from "src/components/Icons/Download";
import Loader from "src/components/Loader";
import { ExportType } from "src/types/enums/ExportRequest";
import { formatDateTime } from "src/utils/format";
import ExportStatusBadge from "./ExportStatusBadge";

interface TableExport extends Export {
  [key: string]: any;
}

interface CenteredCellProps {
  children: React.ReactNode;
  header?: boolean;
}

const CenteredCell: React.FC<CenteredCellProps> = ({ children, header }) => (
  <div style={{ textAlign: "center" }}>
    {header ? <b>{children}</b> : children}
  </div>
);

const ExportLog: React.FC = () => {
  const { data: response = [] } = useGetSIDWISExportsQuery({});
  const [generateCSVExport] = useGenerateCSVExportMutation();

  const handleDownload = async (
    id: number,
    name: string,
    setDownloadState: any
  ): Promise<void> => {
    setDownloadState(true);
    try {
      await generateCSVExport({
        exportId: id,
        name,
      });
    } catch (error) {
      console.error(`Error downloading export with id ${id}:`, error);
    } finally {
      setDownloadState(false);
    }
  };

  const COLUMNS: Array<Column<TableExport>> = [
    {
      Header: <CenteredCell header>Export ID</CenteredCell>,
      name: "Export ID",
      key: "id",
      accessor: (e: Export) => <CenteredCell>{e.id}</CenteredCell>,
    },
    {
      Header: <CenteredCell header>File Name</CenteredCell>,
      name: "File Name",
      key: "fileName",
      accessor: (e: Export) => <CenteredCell>{e.name}</CenteredCell>,
    },
    {
      Header: <CenteredCell header>Submission Period</CenteredCell>,
      name: "Submission Period",
      key: "submissionPeriod",
      accessor: (e: Export) => (
        <CenteredCell>{e.submissionPeriod.name}</CenteredCell>
      ),
    },
    {
      Header: <CenteredCell header>Type</CenteredCell>,
      name: "Type",
      key: "exportType",
      accessor: (e: Export) => {
        return (
          <CenteredCell>
            {e.exportType === ExportType.FullSDWIS
              ? "SDWIS - All"
              : "SDWIS - New"}
          </CenteredCell>
        );
      },
    },
    {
      Header: <CenteredCell header># of Utilities</CenteredCell>,
      name: "# of Utilities",
      key: "rowCount",
      accessor: (e: Export) => <CenteredCell>{e.rowCount / 4}</CenteredCell>,
    },
    {
      Header: <CenteredCell header>Exporter</CenteredCell>,
      name: "Exporter",
      key: "exporter",
      accessor: (e: Export) => <CenteredCell>{e.createdBy}</CenteredCell>,
    },
    {
      Header: <CenteredCell header>Started At</CenteredCell>,
      name: "Started At",
      key: "createdOn",
      accessor: (e: Export) => (
        <CenteredCell>{formatDateTime(e.createdOn)}</CenteredCell>
      ),
    },
    {
      Header: <CenteredCell header>Status</CenteredCell>,
      name: "Status",
      key: "status",
      accessor: (e: Export) => (
        <CenteredCell>
          <ExportStatusBadge status={e.status} errors={e.errors} />
        </CenteredCell>
      ),
    },
    {
      Header: <CenteredCell header>Download</CenteredCell>,
      name: "Download",
      key: "download",
      accessor: (e: Export) => {
        const [downloadState, setDownloadState] = useState(false);

        return (
          <CenteredCell>
            <div
              onClick={(p) => {
                p.preventDefault();
                void handleDownload(e.id, e.name, setDownloadState);
              }}
              style={{ cursor: "pointer" }}
            >
              {downloadState ? <Loader /> : <DownloadIcon />}
            </div>
          </CenteredCell>
        );
      },
    },
  ];

  return (
    <div>
      <h2>Export Log</h2>
      <Table data={response as []} columns={COLUMNS} />;
    </div>
  );
};

export default ExportLog;
