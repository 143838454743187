import { Card } from "@120wateraudit/waterworks";
import React from "react";

import Page from "src/components/Page";
import { SubmissionPeriodDropdown } from "src/modules/Submissions/SubmissionPeriodDropdown";
import styled from "styled-components";
import PageHeader from "src/components/PageHeader";
import SubmissionsStatusesMetricsCard from "./SubimssionsStatusesMetricsCard";
import { PwsSubmissionPeriodsTable } from "src/modules/Submissions/PwsSubmissionPeriodsTable";
import Loader from "src/components/Loader";
import ExportOptionDropdown from "./ExportOptionDropdown";
import { useConfig, Flags } from "src/hooks/useConfig";

const pageContent = (
  submissionPeriodId: number,
  hasStatePortalSidwisExport: boolean
): React.ReactElement => {
  return (
    <>
      <TopSectionWrapper>
        <SubmissionsStatusesMetricsCard
          submissionPeriodId={submissionPeriodId}
        />
        <div style={{ marginBottom: "12px" }}>
          {hasStatePortalSidwisExport && (
            <ExportOptionDropdown submissionPeriodId={submissionPeriodId} />
          )}
        </div>
      </TopSectionWrapper>
      <Card>
        <PwsSubmissionPeriodsTable submissionPeriodId={submissionPeriodId} />
      </Card>
    </>
  );
};

const InventoryPage: React.FC = () => {
  const [submissionPeriodId, setSubmissionPeriodId] = React.useState<number>();
  const [hasStatePortalSidwisExport] = useConfig(Flags.statePortalSidwisExport);

  return (
    <Page
      title={
        <TitleContainer>
          <PageHeader>Submissions</PageHeader>
          <SubmissionPeriodDropdown
            setSubmissionPeriodId={setSubmissionPeriodId}
          />
        </TitleContainer>
      }
    >
      {!submissionPeriodId
      ? (
        <Loader />
      )
      : (
        pageContent(submissionPeriodId, hasStatePortalSidwisExport)
      )}
    </Page>
  );
};

const TitleContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  position: relative;
`;

const TopSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

export default InventoryPage;
