import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { Middleware } from "redux";
import { logger } from "redux-logger";

import tokenReducer from "src/modules/User/slice";
import claimsReducer from "src/modules/User/claimsSlice";
import {
  accountManagementService,
  attachmentsApi,
  stateSubmissionService,
  pwsApi,
  geoserverApi,
  reportingApi,
} from "./services";
import ptdApi from "./services/ptd";

const middlewares: Middleware[] = [];

if (process.env.NODE_ENV === `development`) {
  middlewares.push((logger as any));
}

const rootReducer = combineReducers({
  [accountManagementService.reducerPath]: accountManagementService.reducer,
  [attachmentsApi.reducerPath]: attachmentsApi.reducer,
  [stateSubmissionService.reducerPath]: stateSubmissionService.reducer,
  [pwsApi.reducerPath]: pwsApi.reducer,
  [geoserverApi.reducerPath]: geoserverApi.reducer,
  [reportingApi.reducerPath]: reportingApi.reducer,
  [ptdApi.reducerPath]: ptdApi.reducer,
  token: tokenReducer,
  claims: claimsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      ...middlewares,
      accountManagementService.middleware,
      attachmentsApi.middleware,
      stateSubmissionService.middleware,
      pwsApi.middleware,
      geoserverApi.middleware,
      reportingApi.middleware,
      ptdApi.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// TODO
// if (process.env.NODE_ENV !== "production" && module.hot) {
//   module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
// }

export default store;
