export enum Tag {
  Accounts = "Accounts",
  Attachments = "Attachments",
  Comments = "Comments",
  Counties = "Counties",
  Users = "Users",
  Systems = "Sytems",
  Contacts = "Contacts",
  SubmissionPeriods = "SubmissionPeriods",
  Submissions = "Submissions",
  ServiceLines = "ServiceLines",
  GeoserverInventory = "GeoserverInventory",
  PwsSubmissionPeriods = "PwsSubmissionPeriods",
  PwsSubmissionPeriodStatuses = "PwsSubmissionPeriodStatuses",
  Ptd = "Ptd",
  SavedViews = "SavedViews",
  SubmissionCommunications = "SubmissionCommunications",
  Territories = "Territories"
}

export enum TagLabel {
  List = "LIST",
  Current = "CURRENT",
  Metric = "METRIC",
}
