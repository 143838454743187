import React, { useState, useContext, useEffect } from "react";
import { Button, DropdownProps, Icon } from "semantic-ui-react";
import { ModalContext } from "src/modules/Modal";
import { ExportType } from "src/types/enums/ExportRequest";
import styled from "styled-components";

interface ExportOptionDropdownProps {
  submissionPeriodId: number;
}

const ExportOptionDropdown: React.FC<ExportOptionDropdownProps> = ({
  submissionPeriodId,
}) => {
  const { openModal, closeModal } = useContext(ModalContext);
  const [isSelectionOpen, setIsSelectionOpen] = useState(false);
  const [select, setSelect] = useState<ExportType | null>(null);

  const options = [
    { key: "exportAll", text: "SDWIS - All", value: ExportType.FullSDWIS },
    {
      key: "onlyMostRecent",
      text: "SDWIS - New",
      value: ExportType.MostRecentSDWIS,
    },
  ];

  useEffect(() => {
    if (select !== null) {
      handleOpen(select);
    }
  }, [select]);

  const handleClick = (): void => {
    setIsSelectionOpen(!isSelectionOpen);
  };

  const handleOpen = (exportType: ExportType): void => {
    openModal("exportModal", {
      exportType,
      submissionPeriodId,
      closeModal,
    });
    setSelect(null);
  };

  const handleChange = (
    e: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ): void => {
    setSelect(data.value as ExportType);
  };

  return (
    <>
      <StyledButton
        options={options}
        onClick={handleClick}
        style={{
          backgroundColor: "#00B5E1",
          color: "white",
          textAlign: "center",
        }}
      >
        Export
        <Icon name="dropdown" style={{ textAlign: "right" }} />
      </StyledButton>
      {isSelectionOpen && (
        <DropdownContainer>
          {options.map((option) => (
            <DropdownOption
              key={option.key}
              onClick={(e) => handleChange(e, { value: option.value })}
            >
              {option.text}
            </DropdownOption>
          ))}
        </DropdownContainer>
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  background-color: rgb(0, 181, 225);
  color: white;
  font-weight: 600;
`;

const DropdownContainer = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  position: absolute;
  z-index: 1000;
`;

const DropdownOption = styled.div`
  cursor: pointer;
  padding: 8px;
  &:hover {
    background-color: #f1f1f1;
  }
`;

export default ExportOptionDropdown;
