import React from "react";
import { Badge } from "@120wateraudit/waterworks";
import { ExportStatus } from "src/types/enums/ExportStatus";
import { Popup } from "semantic-ui-react";

interface ExportStatusBadgeProps {
  status: ExportStatus;
  errors: string[];
  style?: React.CSSProperties;
}

const getExportStatusColor = (status: ExportStatus): string => {
  const failed = "#F7685B";
  const processing = "#FFC107";
  const completed = "#16A34A";

  if (status === ExportStatus.Error) {
    return failed;
  } else if (status === ExportStatus.Processing) {
    return processing;
  } else if (status === ExportStatus.Completed) {
    return completed;
  }
  return "#FFFFFF";
};

export const ExportStatusBadge: React.FC<ExportStatusBadgeProps> = ({
  status,
  errors,
  style,
}) => {
  const errorsList = errors.map((error: string, index: number) => (
    <p key={index}>{error}</p>
  ));
  const statusColor = getExportStatusColor(status);
  const errorDialogContent = <div>{errorsList}</div>;
  const badge = (
    <Badge
      style={{
        ...style,
        justifyContent: "center",
        textAlign: "center",
        minWidth: "80px",
        color: "#FFFFFF",
      }}
      backgroundColor={statusColor}
    >
      {status}
    </Badge>
  );

  return status === ExportStatus.Error
  ? (
    <Popup
      trigger={<div>{badge}</div>}
      content={errorDialogContent}
      position="top center"
    />
  )
  : (
    <div>{badge}</div>
  );
};

export default ExportStatusBadge;
